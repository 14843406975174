<template>
  <v-dialog v-model="dialogProp" width="600" persistent>
    <v-card>
      <v-card-title class="headline primary white--text" error-title color="primary">
        {{ rescheduleVaccineAppointment ? 'Reschedule appointment' : 'Create appointment' }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="my-4">
        <v-form ref="vaccineForm">
          <v-alert type="error" v-if="formError" dense dismissible class="mt-4">Please fill in all required fields</v-alert>
          <v-container style="padding-top: 20px">
            <v-row justify="center">
              <v-col cols="12" md="6">
                <v-dialog
                  ref="dialogAppointmentDate"
                  v-model="dialogAppointmentDate"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :class="required ? 'required' : ''"
                      :value="computedAppointmentDate"
                      required
                      clearable
                      label="Appointment date"
                      append-icon="mdi-calendar"
                      readonly
                      v-on="on"
                      class="required"
                      :rules="[(v) => !!v || 'This field is required']"
                    ></v-text-field>
                  </template>
                  <v-date-picker ref="picker" v-model="appointmentDate" min="1900-01-01" @change="dateChosen"></v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="12" md="6">
                <div class="required custom-time-picker-wrapper" :class="vErrors.appointmentTime && 'error--text'">
                  <label>Time</label>
                  <vue-timepicker
                    v-if="dialogProp"
                    manual-input
                    v-model="formData.appointmentTime"
                    close-on-complete
                    :minute-interval="minuteIntervalTimePicker"
                    format="hh:mm A"
                  ></vue-timepicker>
                  <div v-if="vErrors.appointmentTime" class="v-messages__message">{{ vErrors.appointmentTime }}</div>
                </div>
              </v-col>
            </v-row>

            <v-row justify="center" v-if="!vaccine">
              <v-col cols="12" md="6">
                <v-select
                  v-model="formData.location"
                  :loading="loadingLocations"
                  :disabled="loadingLocations"
                  :items="testLocations"
                  class="required"
                  :rules="[(v) => !!v || 'This field is required']"
                  item-value="id"
                  item-text="name"
                  label="Select Location"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="cancel()" :disabled="creatingAppointment">Cancel</v-btn>
        <v-btn
          color="primary"
          v-if="rescheduleVaccineAppointment"
          large
          @click="editAppointment()"
          :disabled="creatingAppointment"
          :loading="creatingAppointment"
        >
          Save
        </v-btn>
        <v-btn
          color="primary"
          v-if="!rescheduleVaccineAppointment"
          large
          @click="createAppointment()"
          :disabled="creatingAppointment"
          :loading="creatingAppointment"
        >
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
import SharedFunctions from '@/mixins/SharedFunctions.vue';

export default {
  mixins: [SharedFunctions],
  props: ['dialogProp', 'caseSelectedData', 'prefillNow', 'vaccineDataPatientCase'],
  components: { VueTimepicker },
  data() {
    return {
      dialogVaccine: false,
      loadingLocations: false,
      formData: {
        appointmentDate: null,
        appointmentTime: null,
        location: null
      },
      vErrors: {
        appointmentDate: null,
        appointmentTime: null,
        location: null
      },
      appointmentDate: null,
      datePickerRules: [],
      durationTimeSlots: [5, 10, 15],
      minuteIntervalTimePicker: 5,
      dialogAppointmentDate: false,
      creatingAppointment: false,
      testLocations: []
    };
  },
  computed: {
    vaccineLocations() {
      return this.$store.state.vaccine.locations;
    },
    computedAppointmentDate() {
      return this.appointmentDate ? moment(this.appointmentDate).format('D-MMMM-YYYY') : '';
    },
    rescheduleVaccineAppointment() {
      if (this.caseSelectedData && this.vaccineDataPatientCase && this.vaccineDataPatientCase.vaccine1Dose) return true;
      else return false;
    },
    vaccine() {
      if (this.caseSelectedData && this.vaccineDataPatientCase) return this.vaccineDataPatientCase;
      else return false;
    }
  },
  watch: {
    vaccineLocations(value) {
      if (value) {
        this.testLocations = value.filter((v) => v.dossageAllow === true);
      }
    }
  },
  created() {
    if (this.vaccineLocations.length == 0) {
      this.getVaccineLocations();
    } else {
      this.testLocations = this.vaccineLocations.filter((v) => v.dossageAllow === true);
    }
    if (this.prefillNow) this.prefillInCurrentDateTimeLocation();
    else this.prefillSecondAppointment();
  },
  methods: {
    createAppointment() {
      if (!this.$refs.vaccineForm.validate() || !this.customValidationValid()) return;
      this.creatingAppointment = true;
      const date = this.formatDateForSubmission(this.formData.appointmentDate, this.formData.appointmentTime);
      const id = this.caseSelectedData.id;

      const payload = {
        patientCase: {
          id: id
        },
        appointment1: date,
        appointmentLocation: {
          id: this.formData.location
        }
      };

      this.$store.dispatch('createVaccineAppointment', payload).then((data) => {
        if (!data) return;
        this.$emit('vaccineCalendarDialogCallback', {
          done: () => {
            this.creatingAppointment = false;
            this.$store.commit('SET_SNACKBAR', { open: true, text: 'Appointment successfully created.', color: 'success' });
          }
        });
      });
    },
    editAppointment() {
      if (!this.$refs.vaccineForm.validate() || !this.customValidationValid()) return;
      this.creatingAppointment = true;
      const date = this.formatDateForSubmission(this.formData.appointmentDate, this.formData.appointmentTime);

      let payload = this.vaccine;
      payload.appointment2 = date;

      this.$store.dispatch('updateVaccineAppointment', payload).then((data) => {
        if (!data) return;
        this.$emit('vaccineCalendarDialogCallback', {
          done: () => {
            this.creatingAppointment = false;
            this.$store.commit('SET_SNACKBAR', { open: true, text: 'Appointment successfully edited.', color: 'success' });
          }
        });
      });
    },
    getVaccineLocations() {
      this.loadingLocations = true;
      this.$store.dispatch('getVaccineLocations').finally(() => {
        this.loadingLocations = false;
      });
    },
    dateChosen(date) {
      this.$refs.dialogAppointmentDate.save(date);
      this.formData.appointmentDate = date + 'T13:00:00.000Z[UTC]';
    },
    formatDateForSubmission(date, timeObj) {
      const time = timeObj.hh + ':' + timeObj.mm + ' ' + timeObj.A;
      const dateFormatted = moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
      const timeFormatted = moment.utc(time, 'hh:mm A').utcOffset(240).format('HH:mm');
      return `${dateFormatted}T${timeFormatted}:00Z[UTC]`;
    },
    cancel() {
      this.$parent.vaccineCalendarDialog = false;
    },
    customValidationValid() {
      let validator = [];
      let isValid = false;
      validator[0] = this.customValidatorTimePicker(this.formData.appointmentTime, 'appointmentTime', {
        required: true,
        validTime: true
      });
      if (validator.includes(false)) isValid = false;
      else isValid = true;
      return isValid;
    },
    customValidatorTimePicker(value, key, rules) {
      if (rules.required && !value) {
        this.vErrors[key] = 'This field is required';
        return false;
      } else {
        this.vErrors[key] = null;
      }

      if (rules.validTime) {
        const time = value.hh + ':' + value.mm + ' ' + value.A;
        if (!moment(time, 'hh:mm A', true).isValid()) {
          this.vErrors[key] = 'Is not a valid time input';
          return false;
        } else {
          this.vErrors[key] = null;
        }
      }

      return true;
    },
    prefillSecondAppointment() {
      if (this.caseSelectedData && this.vaccineDataPatientCase) {
        const vaccine = this.vaccineDataPatientCase;
        if (vaccine.vaccine1Dose && !vaccine.vaccine2Dose && vaccine.appointment2) {
          this.appointmentDate = moment.utc(vaccine.appointment2, 'YYYY-MM-DDTHH:mm').local().format('YYYY-MM-DD');
          this.formData.appointmentDate = this.appointmentDate;
          this.formData.appointmentTime = {
            hh: moment.utc(vaccine.appointment2, 'YYYY-MM-DDTHH:mm').local().format('hh'),
            mm: moment.utc(vaccine.appointment2, 'YYYY-MM-DDTHH:mm').local().format('mm'),
            A: moment.utc(vaccine.appointment2, 'YYYY-MM-DDTHH:mm').local().format('A')
          };
        }
      }
    },
    prefillInCurrentDateTimeLocation() {
      this.appointmentDate = moment.utc().local().format('YYYY-MM-DD');
      this.formData.appointmentDate = this.appointmentDate;
      this.formData.appointmentTime = {
        hh: moment.utc().local().format('hh'),
        mm: this.round5(moment.utc().local().format('mm')),
        A: moment.utc().local().format('A')
      };
      this.formData.location = this.getLocalStorageObject('dvg_vaccine_data', 'location');
    },
    round5(x) {
      let roundedValue = Math.ceil(+x / 5) * 5;
      roundedValue = roundedValue.toString();
      if (roundedValue.length == 1) roundedValue = '0' + roundedValue;
      return roundedValue;
    }
  }
};
</script>

<style lang="scss">
.custom-time-picker-wrapper {
  &.error--text {
    .vue__time-picker {
      input {
        border: 2px solid #b71c1c !important;
      }
    }
    .v-messages__message {
      font-size: 12px;
      line-height: 12px;
      margin: 3px 0 0 10px;
    }
  }
  .vue__time-picker {
    display: block;
    width: 100%;
    input {
      width: 100% !important;
      height: 40px !important;
      padding: 8px !important;
      border: none !important;
      border: 1px solid rgb(118, 118, 118) !important;
      font-size: 16px !important;
      border-radius: 3px;
    }
    .dropdown {
      width: 100%;
      .select-list {
        width: 100%;
      }
      ul li:not([disabled]).active,
      ul li:not([disabled]).active:hover {
        background: #366baf;
        color: #fff;
      }
    }
  }
}
</style>
