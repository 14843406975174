<template>
  <v-card
    @click="$emit('setSelectedBox', box)"
    :disabled="box.location.dossageAllow ? false : true"
    :class="selectedBox.id == box.id ? 'active-card' : ''"
    class="mb-4 relation-card pb-2 pt-0 elevation-0 inventory-card"
    outlined
  >
    <v-card-text class="pt-3">
      <div class="overline color" style="position: absolute; bottom: 5px; right: 8px">
        <span class="">
          {{ selectedBox.id == box.id ? 'Selected' : '' }}
        </span>
      </div>

      <!-- USER INFO -->
      <div class="">
        <div class="content">
          <v-card-title class="pt-0 px-0 pb-0 mb-0">{{ box.brand.brandName }}</v-card-title>
          <span class="label">Batch number: </span>
          <div class="box-number caption">{{ box.boxNumber }}</div>
          <div>
            <span class="label">Amount of vials: </span>
            <span class="value"
              >{{ box.amountOfVials }} <span class="caption label ms-1">({{ box.dosagePerVial }} doses per vial)</span></span
            >
          </div>
          <div>
            <span class="label">Doses left: </span>
            <span class="value">{{ box.currentDosageLevel }}</span>
          </div>
          <div>
            <span class="label">Transfer date: </span>
            <span class="value">{{ formatDate(box.creation) }}</span>
          </div>
          <div v-if="box.dosisExpiration">
            <span class="label">Expire date: </span>
            <span class="value">{{ formatDate(box.dosisExpiration) }}</span>
          </div>
        </div>

        <v-avatar class="ma-2 check-icon-wrapper box d-none d-lg-flex" size="70" rounded>
          <v-icon>mdi-package-variant-closed</v-icon>
        </v-avatar>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import SharedFunctions from '@/mixins/SharedFunctions.vue';
export default {
  mixins: [SharedFunctions],
  props: ['box', 'selectedBox'],
  data() {
    return {};
  },
  methods: {
    setSelectedBoxChild(box) {
      this.$emit('setSelectedBox', box);
    },
  },
};
</script>

<style lang="scss">
.inventory-card {
  border-radius: 10px !important;
  &.active-card {
    border: 3px solid #2196f3 !important;
    .overline.color {
      color: #2196f3;
      font-weight: 600;
      font-size: 14px !important;
    }
  }
  .box {
    position: absolute !important;
    top: 8px;
    right: 8px;
    background: whitesmoke !important;
  }
}
</style>